<template>
    <div class="warp"
         v-loading="Loading"
         element-loading-text="拼命加载中"
         element-loading-spinner="el-icon-loading"
         element-loading-background="rgba(0, 0, 0, 0.8)">
      <div class="tab tab-top">
        <div class="tab-item toptab"
             :class="{active:index==0}"
             @click="MenuActive(0)">邮件功能</div>
        <div class="tab-item toptab"
             v-if="info.cz.length >= 1 || info.gn.includes(1)"
             :class="{active:index==1}"
             @click="MenuActive(1)">其他功能</div>
        <div class="tab-item toptab"
             v-if="info.members_type == 1"
             :class="{active:index==2}"
             @click="MenuActive(2)">授权开通</div>
        <div>
          <p style="padding: 6px 6px; float: left; margin-left: 14px"
             v-if="info.members_type == 1">
            <span>状态：<span v-if="info.members == 0"
                    style="color:#d42929;">未授权</span><span v-if="info.members == 1"
                    style="color:#d2ff1d;">会员</span></span>
          </p>
          <p style="padding: 6px 6px; float: left; margin-left: 14px"
             v-if="info.members_type == 1">
            <span>到期：<span v-if="info.members == 0"
                    style="color:#d42929;"></span><span v-if="info.members == 1"
                    style="color:#d2ff1d;">{{info.end_members}}</span></span>
          </p>
          <p style="padding: 6px 6px; float: right">
            <span>当前角色：{{ info.charac_name }}
              <span style="color: #797979;cursor:pointer"
                    @click="LoginDisplay = 'display:block'">《换绑》</span></span>
          </p>
        </div>
      </div>
      <div class="main">
        <div class="page page-1 active">
          <div style="padding:0px 8px;">
            <el-row :gutter="12">
              <el-col :span="10"
                      v-if="info.code_type == 0">
                <div style="margin:30px 0px 14px 0px">
                  <select v-model="items.type"
                          style="width:52px;background:#101010;color:#808080;padding:0px 2px;border:1px solid #444;height:22px;margin-right:4px;">
                    <option value="0">全部</option>
                    <option :value="1">装备</option>
                    <option :value="2">道具/材料</option>
                  </select>
                  <input v-model="items.keywords"
                         type="text"
                         style="width:94px;border: 1px solid#444;height:20px;color:#808080;padding: 0px 6px;"
                         placeholder="物品ID/物品名称">
  
                  <span class="query-an"
                        style="margin-left:4px;cursor:pointer;"
                        @click="getItemsQuery">查询</span>
                </div>
                <div class="tr">
                  <span class="th-a">物品ID</span><span>物品名称</span>
                </div>
                <table style="background:#232323;">
                  <div v-if="items.data.length >= 1">
                    <tr class="tt"
                        v-for="(v, i) in items.data"
                        :key="i"
                        @click="ItemsClick(v.code)">
                      <td class="td-a">{{v.code}}</td>
                      <td>{{v.name}}</td>
                    </tr>
                  </div>
                  <div v-else
                       style="margin-top:118px;text-align:center;">
                    <span>无数据</span>
                  </div>
  
                </table>
  
                <div style="text-align:center;"
                     class="iyme-fy">
                  <el-pagination small
                                 :current-page="items.page"
                                 :page-size="items.limit"
                                 layout="prev, pager, next"
                                 :total="items.total"
                                 @current-change="PagingClick">
                  </el-pagination>
                </div>
              </el-col>
              <el-col :span="10"
                      v-else>
                <div class="tr"
                     style="margin-top:40px;">
                  <span class="th-a">物品ID</span><span>物品名称</span>
                </div>
                <table style="background:#232323;">
                  <div v-if="info.code_text.length >= 1">
                    <tr class="tt"
                        v-for="(v, i) in info.code_text"
                        :key="i"
                        @click="ItemsClick(v.code)">
                      <td class="td-a">{{v.code}}</td>
                      <td>{{v.name}}</td>
                    </tr>
                  </div>
                  <div v-else
                       style="margin-top:118px;text-align:center;">
                    <span>无数据</span>
                  </div>
                </table>
              </el-col>
              <el-col :span="14">
                <div v-if="info.members_type == 0"
                     style="text-align:center;margin-top:20px;">
                  <img src="../../../public/static/plug/gm/static/images/icon0.png" />
                </div>
                <div v-else-if="info.members_type == 1 && info.members == 0"
                     style="text-align:center;margin-top:20px;">
                  <img src="../../../public/static/plug/gm/static/images/icon0.png" />
                </div>
                <div v-else-if="info.members_type == 1 && info.members == 1"
                     style="text-align:center;margin-top:20px;">
                  <img src="../../../public/static/plug/gm/static/images/icon1.png" />
                </div>
                <div v-else
                     style="text-align:center;margin-top:20px;">
                  <img src="../../../public/static/plug/gm/static/images/icon0.png" />
                </div>
                <div style="margin-left:20px;">
                  <p style="margin-bottom:6px;">
                    <span>发送类型：</span>
                    <select v-model="meil.items_type"
                            style="width:94px;background:#101010;color:#808080;padding:0px 2px;border:1px solid #444;height:22px;">
                      <option :value="0">普通邮件</option>
                      <option :value="1">时装邮件</option>
                      <option :value="2">宠物邮件</option>
  
                    </select>
  
                    <span v-if="meil.items_type == 2"
                          style="margin-left:9px;">宠物类型：</span>
                    <select v-if="meil.items_type == 2"
                            v-model="meil.pet_type"
                            style="width:94px;background:#101010;color:#808080;padding:0px 2px;border:1px solid #444;height:22px;">
                      <option :value="0">宠物蛋</option>
                      <option :value="1">宠物</option>
                    </select>
                  </p>
                  <p v-if="meil.items_type == 1 || meil.items_type == 2"
                     style="margin-bottom:6px;">
                    <span>限用天数：</span><input type="number"
                           v-model="meil.limit_time"
                           min="0"
                           max="9999999"
                           style="width:243px;border: 1px solid #444;color:#808080;padding: 0px 6px;">
                  </p>
                  <p style="margin-bottom:6px;">
                    <span>物品代码：</span><input type="text"
                           v-model="meil.item_id"
                           style="width:80px;border: 1px solid #444;color:#808080;padding: 0px 6px;">
                    <span style="margin-left:9px;">物品数量：</span><input type="number"
                           v-model="meil.item_num"
                           min="0"
                           max="99999999999"
                           style="width:80px;border: 1px solid #444;color:#808080;padding: 0px 6px;">
                  </p>
                  <p style="margin-bottom:6px;">
                    <span>附件金币：</span><input type="number"
                           v-model="meil.yxb_num"
                           min="0"
                           max="99999999999"
                           style="width:80px;border: 1px solid #444;color:#808080;padding: 0px 6px;">
                    <span style="margin-left:9px;">强化数量：</span><input type="number"
                           v-model="meil.qianghua_num"
                           min="0"
                           max="99999999999"
                           style="width:80px;border: 1px solid #444;color:#808080;padding: 0px 6px;">
                  </p>
                  <p style="margin-bottom:6px;"
                     v-if="info.gn.includes(2)">
                    <span>增幅附加：</span>
                    <select v-model="meil.redfujia"
                            style="width:94px;background:#101010;color:#808080;padding:0px 2px;border:1px solid #444;height:22px;">
                      <option :value="0">无</option>
                      <option :value="1">体力</option>
                      <option :value="2">精力</option>
                      <option :value="3">力量</option>
                      <option :value="4">智力</option>
                    </select>
                    <span style="margin-left:9px;">增幅数值：</span><input type="number"
                           v-model="meil.redtype_num"
                           min="0"
                           max="99999999999"
                           style="width:80px;border: 1px solid #444;color:#808080;padding: 0px 6px;">
                  </p>
                  <div style="text-align:center;margin-top:16px;">
                    <button class="send-button"
                            style="cursor:pointer;margin-right: 30px;"
                            @click="SendMail">确定发送</button>
                    <button class="send-button"
                            style="cursor:pointer;" @click="UpdataSpecial(1)">清理邮件</button>
                  </div>
  
                </div>
              </el-col>
  
              <el-col :span="24"
                      v-if="info.gn.length >= 1">
                <div style="text-align:center;margin-top: 20px;">
                  <button class="send-button"
                          v-if="info.gn.includes(3)"
                          style="cursor:pointer;margin-right:12px;" @click="UpdataSpecial(3)">清理背包</button>
                  <button class="send-button"
                          v-if="info.gn.includes(4)"
                          style="cursor:pointer;margin-right:12px;" @click="UpdataSpecial(4)">清理时装</button>
                  <button class="send-button"
                          v-if="info.gn.includes(5)"
                          style="cursor:pointer;;margin-right:12px;"  @click="UpdataSpecial(5)">清理宠物</button>
                  <button class="send-button"
                          v-if="info.gn.includes(6)"
                          style="cursor:pointer;margin-right:12px;" @click="UpdataSpecial(6)">开放全图</button>
                  <button class="send-button"
                          v-if="info.gn.includes(7)"
                          style="cursor:pointer;margin-right:12px;" @click="UpdataSpecial(7)">开左右槽</button>
                  <button class="send-button"
                          v-if="info.gn.includes(8)"
                          style="cursor:pointer;margin-right:12px;" @click="UpdataSpecial(8)">清理任务</button>
                  <button class="send-button"
                          v-if="info.gn.includes(9)"
                          style="cursor:pointer;" @click="UpdataSpecial(9)">无限负重</button>
  
                </div>
                <div style="text-align:center;margin-top: 10px;">
                  <button class="send-button"
                          v-if="info.gn.includes(10)"
                          style="cursor:pointer;margin-right:12px;" @click="UpdataSpecial(10)">副职业直满级</button>
                  <button class="send-button"
                          v-if="info.gn.includes(11)"
                          style="cursor:pointer;margin-right:12px;" @click="UpdataSpecial(11)">解除装备限制</button>
                  <button class="send-button"
                          v-if="info.gn.includes(12)"
                          style="cursor:pointer;margin-right:12px;" @click="UpdataSpecial(12)">解除角色限制</button>
                  <button class="send-button"
                          v-if="info.gn.includes(13)"
                          style="cursor:pointer;" @click="UpdataSpecial(13)">清空点卷代币</button>
                </div>
              </el-col>
            </el-row>
          </div>
  
        </div>
        <div class="page page-2">
          <el-row :gutter="12">
            <el-col :span="24"
                    v-if="info.cz.length >= 1">
              <div style="border:1px solid #616161;margin:34px 14px 0px 14px;padding:10px 10px;">
                <span style="position:relative;left:278px;top:-20px;">充值</span>
                <p style="margin-bottom:6px;">
                  <span>选择类型：</span>
                  <select v-model="cz.type"
                          style="width:90px;background:#101010;color:#808080;padding:0px 2px;border:1px solid #444;height:22px;">
                    <option :value="0">请选择</option>
                    <option :value="1"
                            v-if="info.cz.includes(1)">点卷</option>
                    <option :value="2"
                            v-if="info.cz.includes(2)">代币</option>
                    <option :value="3"
                            v-if="info.cz.includes(3)">金币</option>
                    <option :value="4"
                            v-if="info.cz.includes(4)">PVP胜点</option>
                    <option :value="5"
                            v-if="info.cz.includes(5)">PVP胜场</option>
                    <option :value="6"
                            v-if="info.cz.includes(6)">PVP段位</option>
                    <option :value="7"
                            v-if="info.cz.includes(7)">SP点</option>
                    <option :value="8"
                            v-if="info.cz.includes(8)">QP点</option>
                    <option :value="9"
                            v-if="info.cz.includes(9)">TP点</option>
                    <option :value="10"
                            v-if="info.cz.includes(10)">修改等级</option>
                  </select>
  
                  <span style="margin-left:24px;">充值数值：</span><input v-model="cz.num"
                         type="number"
                         min="1"
                         max="99999999999"
                         style="width:128px;border: 1px solid #444;color:#808080;padding: 0px 6px;">
                </p>
                <button class="send-button"
                        style="cursor:pointer;position:relative;left:270px;top:4px;"
                        @click="SetGmUp">充值</button>
              </div>
            </el-col>
            <el-col :span="24"
                    v-if="info.gn.includes(1)">
              <div style="border:1px solid #616161;margin:34px 14px 0px 14px;padding:10px 10px;">
                <span style="position:relative;left:278px;top:-20px;">转职</span>
                <p style="margin-bottom:6px;" class="jobd">
                  <span>当前职业：</span>
                  <el-cascader
                      :options="jobdata"
                      style="width: 88%"
                      @change="JobClick"
                    >
                      <template slot-scope="{ node, data }">
                        <span>{{ data.label }}</span>
                        <span v-if="!node.isLeaf">
                          ({{ data.children.length }})
                        </span>
                      </template>
                    </el-cascader>
                </p>
                <button class="send-button"
                        style="cursor:pointer;position:relative;left:270px;top:-4px;"
                        @click="UpdateGmjob">确认</button>
              </div>
            </el-col>
          </el-row>
        </div>
  
        <div class="page page-3">
          <div class="row"
               style="margin-right:0px;margin-left:0px;">
            <div class="col-sm-12"
                 style="margin-top:145px;color:#adb80c;font-size: 12px;padding:0px 86px;">
              <p style="margin-bottom:0px">开通权限说明：</p>
              <p style="margin-bottom:0px">1、激活权限需要激活卡，激活卡去通过游戏商家或<span style="color:#64676b;"
                      @click="CloudDiskCopy(info.buy_url)">《复制购买地址》</span>购买即可。</p>
            </div>
            <div class="col-sm-12"
                 style="margin-top:4px;text-align:center;">
              <p style="padding:22px 0px;">
                <input placeholder="请输入激活卡进行激活权限"
                       v-model="camilo"
                       class="service__input"
                       style="height: 36px;width: 410px;border:1px solid #678267;background:#c5b48e;"><span style="cursor:pointer;padding:13px 14px;background:#0d0a8c;border-radius:2px;color: aliceblue;"
                      @click="Activation">激活</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="alert">
        <!-- 弹出框 -->
        <div class="alert_bg"
             :style="MessageDisplay">
          <div class="alert_main">
            <div class="alert_title"><span>提示</span></div>
            <div class="alert_con">
              <div class="alert_com">
                <span>{{ MessageMsg }}</span>
                <div class="btn"
                     @click="CloseAlert">关闭</div>
              </div>
            </div>
            <div class="alert_bottom"><span></span></div>
          </div>
        </div>
        <div class="alert_bg"
             :style="SuccessDisplay">
          <div class="alert_main">
            <div class="alert_title"><span>提示</span></div>
            <div class="alert_con">
              <div class="alert_com">
                <span>{{ MessageMsg }}</span>
                <div class="btn"
                     @click="SuccessAlert">关闭</div>
              </div>
            </div>
            <div class="alert_bottom"><span></span></div>
          </div>
        </div>
        <!-- 弹出框end -->
        <!-- 登陆框 -->
        <div class="alert_bg"
             :style="LoginDisplay">
          <div class="alert_main"
               style="margin-top: 86px; width: 350px">
            <div class="alert_title"><span>绑定|切换</span></div>
            <div class="alert_con">
              <div class="alert_com">
                <p style="margin-bottom: 6px; display: flex">
                  <span style="padding: 0px 0px 0px 42px">游戏账号：</span><input type="text"
                         v-model="login_info.username"
                         class="service__input" />
                </p>
                <p style="margin-bottom: 6px; display: flex">
                  <span style="padding: 0px 0px 0px 42px">游戏密码：</span><input type="password"
                         v-model="login_info.password"
                         class="service__input" />
                </p>
                <p style="margin-bottom: 6px; display: flex">
                  <span style="padding: 0px 0px 0px 42px">绑定角色：</span>
                  <select v-model="login_info.role"
                          style="width: 174px">
                    <option v-for="(itmec, i) in role_data"
                            :key="i"
                            :value="itmec.charac_no">
                      {{ itmec.charac_name }}
                    </option>
                  </select>
                  <span style="background: #7478e4;padding: 3px 10px;
                        margin-left: 2px;
                        border-radius: 4%;
                      "
                        @click="getRole">获取</span>
                </p>
                <div>
                  <span style="padding: 0px 0px; color: #f90000"><span style="display: inline-block; padding: 0px 0px">{{
                                            LoginError
                                            }}</span></span>
                </div>
                <div style="text-align: center; padding: 10px 0px">
                  <div class="btn"
                       @click="login"
                       style="margin: 0px 0px; display: inline-block">
                    绑定
                  </div>
                  <div class="btn"
                       @click="LoginDisplay = 'display:none'"
                       style="margin: 0px 0px; display: inline-block">
                    取消
                  </div>
                </div>
              </div>
            </div>
            <div class="alert_bottom"><span></span></div>
          </div>
        </div>
      </div>
    </div>
  </template>
    
    
  <script>
  import $ from "jquery";
  import Vue from "vue";
  
  export default {
    name: "Gm",
    data () {
      return {
        MessageDisplay: "display:none", //弹出框显示
        SuccessDisplay: "display:none", //成功弹出框
        LoginDisplay: "display:none", //登陆弹出框
        PayDisplay: "display:none", //购买弹出框
        BuyDisplay: "display:none", //升级弹出框
        LoginError: "",
        MessageMsg: "", //弹出框消息
        Loading: false,
        index: 0,
        info: {
          members: 0, //会员状态 0未授权 会员
          end_members: '', //会员到期时间
          charac_name: '', //当前绑定角色
          code_type: 0,
          cz: [],
          gn: [],
          code_text: [],
          members_type: 0,
          buy_url: '',
        },
        items: {
          page: 1,
          limit: 100,
          total: 0,
          data: [],
          keywords: '',
          type: 0,
        },
        //邮件
        meil: {
          key: this.$route.query.key,
          keyid:this.$route.query.id,
          items_type: 0,
          pet_type: 0,
          limit_time: 0,
          item_id: '',
          item_num: 1,
          yxb_num: 0,
          qianghua_num: 0,
          redfujia: 0,
          redtype_num: 0,
        },
        login_info: {
          username: "",
          password: "",
          role: "",
        },
        cz: {
          key: this.$route.query.key,
          keyid:this.$route.query.id,
          type: 0,
          num: 1,
        },
        zz:{
          key: this.$route.query.key,
          keyid:this.$route.query.id,
          job: 0,
          grow_type: 0,
        },
        jobdata: [
            {
              value: '0',
              label: '鬼剑士',
              children: [
                {
                  value: '17',
                  label: '剑魂',
                },
                {
                  value: '18',
                  label: '鬼泣',
                },
                {
                  value: '19',
                  label: '狂战士',
                },
                {
                  value: '20',
                  label: '阿修罗',
                },
              ],
            },
  
            {
              value: '1',
              label: '女格斗',
              children: [
                {
                  value: '17',
                  label: '气功师',
                },
                {
                  value: '18',
                  label: '散打',
                },
                {
                  value: '19',
                  label: '街霸',
                },
                {
                  value: '20',
                  label: '柔道',
                },
              ],
            },
  
            {
              value: '2',
              label: '男枪手',
              children: [
                {
                  value: '17',
                  label: '漫游枪手',
                },
                {
                  value: '18',
                  label: '枪炮师',
                },
                {
                  value: '19',
                  label: '机械师',
                },
                {
                  value: '20',
                  label: '弹药专家',
                },
              ],
            },
  
            {
              value: '3',
              label: '女法师',
              children: [
                {
                  value: '17',
                  label: '元素法师',
                },
                {
                  value: '18',
                  label: '召唤法师',
                },
                {
                  value: '19',
                  label: '战斗法师',
                },
                {
                  value: '20',
                  label: '魔道学者',
                },
              ],
            },
  
            {
              value: '4',
              label: '圣职者',
              children: [
                {
                  value: '17',
                  label: '圣骑士',
                },
                {
                  value: '18',
                  label: '蓝拳圣使',
                },
              ],
            },
  
            {
              value: '5',
              label: '女枪手',
              children: [
                {
                  value: '17',
                  label: '漫游枪手',
                },
                {
                  value: '18',
                  label: '枪炮师',
                },
                {
                  value: '19',
                  label: '机械师',
                },
                {
                  value: '20',
                  label: '弹药专家',
                },
              ],
            },
  
            {
              value: '6',
              label: '暗夜使者',
              children: [
                {
                  value: '17',
                  label: '刺客',
                },
                {
                  value: '18',
                  label: '死灵术士',
                },
              ],
            },
  
            {
              value: '7',
              label: '男格斗',
              children: [
                {
                  value: '17',
                  label: '气功师',
                },
                {
                  value: '18',
                  label: '散打',
                },
                {
                  value: '19',
                  label: '街霸',
                },
                {
                  value: '20',
                  label: '柔道',
                },
              ],
            },
  
            {
              value: '8',
              label: '男法师',
              children: [
                {
                  value: '17',
                  label: '元素爆破',
                },
                {
                  value: '18',
                  label: '冰结师',
                },
              ],
            },
  
            {
              value: '9',
              label: '暗黑骑士',
            },
          ], 
        cdk: "",
        level_num: 1,
        role_data: [],
        camilo: '',
      };
    },
    created () {
      this.getInfo();
    },
    methods: {
      //登陆
      login () {
        if (this.Loading == true) {
          return false;
        }
        this.Loading = true;
        this.axios({
          // 默认请求方式为get
          method: "post",
          url: Vue.prototype.config.webplugurl + "/PlugLogin",
          // 传递参数
          data: {
            key: this.$route.query.key,
            keyid:this.$route.query.id,
            username: this.login_info.username,
            password: this.login_info.password,
            role: this.login_info.role,
          },
          responseType: "json",
        })
          .then((response) => {
            this.Loading = false;
            if (response.data.code == 200) {
              this.LoginDisplay = "display:none";
              this.getInfo();
            } else {
              this.LoginError = response.data.msg;
            }
          })
          .catch((error) => {
            this.Loading = false;
            // 请求失败，
            console.log(error);
          });
      },
      //获取角色
      getRole () {
        if (this.Loading == true) {
          return false;
        }
        this.Loading = true;
        this.axios({
          // 默认请求方式为get
          method: "post",
          url: Vue.prototype.config.webplugurl + "/getRole",
          // 传递参数
          data: {
            key: this.$route.query.key,
            keyid:this.$route.query.id,
            username: this.login_info.username,
            password: this.login_info.password,
          },
          responseType: "json",
        })
          .then((response) => {
            this.Loading = false;
            if (response.data.code == 200) {
              this.role_data = response.data.info;
            } else {
              this.LoginError = response.data.msg;
            }
          })
          .catch((error) => {
            this.Loading = false;
            // 请求失败，
            console.log(error);
          });
      },
      //查询物品
      getItemsQuery () {
        this.items.page = 1
        this.getItems()
      },
      //获取物品
      getItems () {
        this.Loading = true;
        this.axios({
          // 默认请求方式为get
          method: "post",
          url: Vue.prototype.config.webplugurl + "/gm/getItems",
          // 传递参数
          data: {
            key: this.$route.query.key,
            keyid:this.$route.query.id,
            page: this.items.page,
            limit: this.items.limit,
            keywords: this.items.keywords,
            type: this.items.type,
          },
          responseType: "json",
        })
          .then((response) => {
            this.Loading = false;
            if (response.data.code == 200) {
              this.items = response.data.info;
            } else {
              this.PopMessage(false, response.data.msg);
            }
          })
          .catch((error) => {
            this.Loading = false;
            // 请求失败，
            console.log(error);
          });
      },
      PagingClick (page) {
        this.items.page = page
        this.getItems()
      },
      ItemsClick (code) {
        this.meil.item_id = code;
      },
      //获取基本配置信息
      getInfo () {
        this.Loading = true;
        this.axios({
          // 默认请求方式为get
          method: "post",
          url: Vue.prototype.config.webplugurl + "/gm/getGmInfo",
          // 传递参数
          data: {
            key: this.$route.query.key,
            keyid:this.$route.query.id,
          },
          responseType: "json",
        })
          .then((response) => {
            this.Loading = false;
            if (response.data.code == 200) {
              this.info = response.data.info;
              //如果是0 代币不限代码
              if (response.data.info.code_type == 0) {
                this.getItems();
              }
            } else {
              this.PopMessage(true, response.data.msg);
            }
          })
          .catch((error) => {
            this.Loading = false;
            // 请求失败，
            console.log(error);
          });
      },
      //发送邮件
      SendMail () {
        if (this.Loading == true) {
          return false
        }
        this.Loading = true;
        this.axios({
          // 默认请求方式为get
          method: "post",
          url: Vue.prototype.config.webplugurl + "/gm/sendGmMail",
          // 传递参数
          data: this.meil,
          responseType: "json",
        })
          .then((response) => {
            this.Loading = false;
            if (response.data.code == 200) {
              this.PopMessage(false, response.data.msg);
            } else {
              this.PopMessage(false, response.data.msg);
            }
          })
          .catch((error) => {
            this.Loading = false;
            // 请求失败，
            console.log(error);
          });
      },
      //激活
      Activation () {
        if (this.Loading == true) {
          return false
        }
        this.Loading = true;
        this.axios({
          // 默认请求方式为get
          method: "post",
          url: Vue.prototype.config.webplugurl + "/gm/activationGm",
          // 传递参数
          data: {
            key: this.$route.query.key,
            keyid:this.$route.query.id,
            camilo: this.camilo,
          },
          responseType: "json",
        })
          .then((response) => {
            this.Loading = false;
            if (response.data.code == 200) {
              this.PopMessage(true, response.data.msg);
            } else {
              this.PopMessage(false, response.data.msg);
            }
          })
          .catch((error) => {
            this.Loading = false;
            // 请求失败，
            console.log(error);
          });
      },
      //发送充值
      SetGmUp () {
        if (this.Loading == true) {
          return false
        }
        this.Loading = true;
        this.axios({
          // 默认请求方式为get
          method: "post",
          url: Vue.prototype.config.webplugurl + "/gm/setGmUp",
          // 传递参数
          data: this.cz,
          responseType: "json",
        })
          .then((response) => {
            this.Loading = false;
            if (response.data.code == 200) {
              this.PopMessage(false, response.data.msg);
            } else {
              this.PopMessage(false, response.data.msg);
            }
          })
          .catch((error) => {
            this.Loading = false;
            // 请求失败，
            console.log(error);
          });
      },
      //修改转职
      UpdateGmjob () {
        if (this.Loading == true) {
          return false
        }
        this.Loading = true;
        this.axios({
          // 默认请求方式为get
          method: "post",
          url: Vue.prototype.config.webplugurl + "/gm/updateGmjob",
          // 传递参数
          data: this.zz,
          responseType: "json",
        })
          .then((response) => {
            this.Loading = false;
            if (response.data.code == 200) {
              this.PopMessage(false, response.data.msg);
            } else {
              this.PopMessage(false, response.data.msg);
            }
          })
          .catch((error) => {
            this.Loading = false;
            // 请求失败，
            console.log(error);
          });
      },
      //复制
      CloudDiskCopy (vars) {
        //创建input标签
        var input = document.createElement('input')
        //将input的值设置为需要复制的内容
        input.value = vars
        //添加input标签
        document.body.appendChild(input)
        //选中input标签
        input.select()
        //执行复制
        document.execCommand('copy')
        //成功提示信息
        this.PopMessage(false, '复制成功');
        //移除input标签
        document.body.removeChild(input)
  
      },
  
      //弹出框状态
      PopMessage (type, msg) {
        this.SuccessDisplay = "display:none";
        this.MessageDisplay = "display:none";
        this.MessageMsg = msg;
        if (type == false) {
          this.MessageDisplay = "display:block";
        } else {
          this.SuccessDisplay = "display:block";
        }
      },
      UpdataSpecial(type){
        if (this.Loading == true) {
          return false
        }
        this.Loading = true;
        this.axios({
          // 默认请求方式为get
          method: "post",
          url: Vue.prototype.config.webplugurl + "/gm/UpdataSpecial",
          // 传递参数
          data: {
            key: this.$route.query.key,
            keyid:this.$route.query.id,
            type:type,
          },
          responseType: "json",
        })
          .then((response) => {
            this.Loading = false;
            if (response.data.code == 200) {
              this.PopMessage(false, response.data.msg);
            } else {
              this.PopMessage(false, response.data.msg);
            }
          })
          .catch((error) => {
            this.Loading = false;
            // 请求失败，
            console.log(error);
          });
      },
      JobClick(vars) {
          this.zz.job = 0
          this.zz.grow_type = 0
          if (vars.length == 1) {
            this.zz.job = vars[0]
          } else if (vars.length == 2) {
            this.zz.job = vars[0]
            this.zz.grow_type = vars[1]
          } else if (vars.length == 3) {
            this.zz.job = vars[0]
            this.zz.grow_type = vars[2]
          }
        },
  
      //关闭窗口
      CloseAlert () {
        this.SuccessDisplay = "display:none";
        this.MessageDisplay = "display:none";
      },
      SuccessAlert () {
        this.SuccessDisplay = "display:none";
        this.MessageDisplay = "display:none";
        this.getInfo();
      },
      MenuActive (index) {
        this.index = index;
        $('.page').removeClass('active');
        $('.toptab').removeClass('active');
        $(this).addClass('active');
        $('.page').eq(index).addClass('active');
      },
    },
  };
  </script>
    <style scoped>
  @import "../../../public/static/plug/gm/static/css/game.css";
  </style>
  <style lang="scss" scoped>
  .main {
    /deep/ .el-row {
      margin-right: 3px !important;
      margin-left: 3px !important;
    }
  }
  
  .jobd{
    /deep/ .el-input__inner {
      height:20px !important;
      background-color:#101010 !important;
      border: 1px solid #444444 !important;
      border-radius:0 !important;
      font-size:12px !important;
      padding:0 5px  !important;
    }
    
  
   
  }
  
  .iyme-fy {
    /deep/ .el-pagination {
      color: #c5b48e;
    }
    /deep/ .el-pager li {
      background: none !important;
    }
    /deep/ .el-pagination button:disabled {
      background-color: #101010 !important;
    }
    /deep/ .el-pagination .btn-next {
      background: #101010 !important;
    }
    /deep/ .el-pagination .btn-prev,
    .el-pagination .btn-prev {
      background: #101010 !important;
    }
  }
  </style>
    
    <style>
  #root,
  body,
  html {
    min-width: 0px;
  }
  .el-cascader-node {
      height:20px !important;   
  }
  
  .el-cascader__dropdown{
    background: #101010  !important;
    border: 1px solid #616161  !important;
  }
  </style>
    